import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import PageTitle from '../components/PageTitle'
import profile from '../img/profile.svg'
import SEO from '../components/SEO'

export const AboutPageTemplate = ({
  title,
  header,
  content,
  contentComponent,
}) => {
  const PageContent = contentComponent || Content

  return (
    <section>
      <PageTitle>{title}</PageTitle>
      <div className="flex flex-wrap mx-auto mb-12 overflow-hidden bg-white border-gray-900 rounded-full shadow-lg dark:bg-purple-900 focus:outline-none dark:border-white md:w-52 md:h-52 w-36 h-36">
        <img
          className="object-cover w-full h-full"
          alt="profile"
          src={profile}
        />
      </div>
      <div className="flex flex-wrap justify-center">
        <article>
          <h2 className="text-center">{header}</h2>
          <PageContent content={content} />
        </article>
      </div>
    </section>
  )
}

AboutPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  header: PropTypes.string,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const AboutPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.header}
      />
      <AboutPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        header={post.frontmatter.header}
        content={post.html}
      />
    </Layout>
  )
}

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default AboutPage

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        header
      }
    }
  }
`
